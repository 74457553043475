(function() {
    'use strict';

    angular.module('Logger', [])

    // Angular Constants
    // toastr is actually set in toastr.js as window.toastr
    // making globally available here.
    .constant('toastr', toastr)

    .config(toastrConfig);

    /* @ngInject */
    function toastrConfig(toastr) {
        toastr.options.timeOut = 8000;
        toastr.options.positionClass = 'toast-top-right';
    }

})();

(function() {
    'use strict';

    angular
        .module('Logger')
        .factory('logger', logger);

    logger.$inject = ['$log', 'toastr'];

    function logger($log, toastr) {
        var service = {

            // turn off the toasts programmatically.
            showToasts: true,

            error   : error,
            info    : info,
            success : success,
            warn    : warn,
            clear   : clear,
            remove  : remove

        };

        return service;
        /////////////////////

        function error(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.error(message, title, overrideOptions);
            }
            $log.error('Error: ' + message, data);
        }

        function info(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.info(message, title, overrideOptions);
            }
            $log.info('Info: ' + message, data);
        }

        function success(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.success(message, title, overrideOptions);
            }
            $log.info('Success: ' + message, data);
        }

        function warn(message, data, title, overrideOptions) {
            if(service.showToasts){
                toastr.warning(message, title, overrideOptions);
            }
            $log.warn('Warning: ' + message, data);
        }

        function clear(){
            toastr.clear();
        }

        function remove(){
            toastr.remove();
        }

    }
}());

(function(){

    'use strict';

    angular
        .module('Utilities', [])
        .factory('utility', utilities)
        .factory('HttpService',HttpService)
        .directive('httpProgress',httpProgress);

        utilities.$inject = ['$log','$filter','HttpService'];

        function utilities($log,$filter,HttpService) {

            $log.log('Js Utilities has loaded.');

            var factory = {
                routeParams     : routeParams,
                odbcToNGDate    : odbcToNGDate,
                HttpService     : HttpService
            }

            return factory;

            // returns url route friendly string: /:wbs1/:wbs2?/:wbs3? 
            function routeParams(wbs1,wbs2,wbs3){
                var wbs1 = wbs1 || ''; // equivalent to sql coalesce
                var wbs2 = wbs2 || '';
                var wbs3 = wbs3 || '';
                wbs1 = wbs1.toString();
                wbs2 = wbs2.toString();
                wbs3 = wbs3.toString();
                var route = "";
                if( wbs1.length > 0 && wbs1.length < 15 ){
                    route = wbs1;
                    if( wbs2.length > 0 && wbs2.length < 6 ){
                        route += "/" + wbs2;
                        if( wbs3.length > 0 && wbs3.length < 6){
                            route += "/" + wbs3;
                        }
                    }
                    return route;
                }
                return "INVALIDWBS1";
            };

            // convert sql odbc datetime to angular date
            function odbcToNGDate(odbcdate,format){
                var format = format || 'longDate'; // 'shortDate'
                var date = new Date(odbcdate);
                return $filter('date')(date,format);
            }

        };

        HttpService.$inject = ['$http','$q','$log','logger'];

        function HttpService($http,$q,$log,logger){
            var factory = {
                sendRequest: sendRequest,
                post: post
            };
            return factory;

            //Send HTTP Request
            function sendRequest(url,params,method){
                if(method == undefined)
                    method="GET";
                else
                    method="POST";
                

                var request = $http({
                     method: method,
                     url: _hostName + url,
                     data: params
                 });
                return(request.then(handleSuccess,handleError));
            };

            //Send HTTP Request
            function post(url,params,method){
                if(method == undefined)
                    method="GET";
                else
                    method="POST";
                

                var request = $http({
                     method: method,
                     url: _hostName + url,
                     params: params
                 });
                return(request.then(handleSuccess,handleError));
            };

            //Error Handler
            function handleError(response) {
                if (!angular.isObject( response.data ) || ! response.data.message){
                    logger.error("An unknown error occurred.");
                    return($q.reject("An unknown error occurred."));
                }
                logger.error(response.data.message, {}, 'Request Error', {timeOut: 10000,positionClass:'toast-bottom-right'});
                return($q.reject(response.data.message));
            }

            //Success Handler
            function handleSuccess(response){
                return response.data;
            }
        }

        function httpProgress(){
            return {
                restrict: 'E',
                controller: httpProgressController,
                templateUrl: '/assets/js/Utilities/http-progress.html'
            };
        }

        function httpProgressController($scope){
            
        }

})();

(function(){

    'use strict';

    angular
        .module('app.core', [
             'ngMeta'    
            ,'ngRoute'
            ,'Logger'
            ,'angular-loading-bar'    
            ,'Utilities'            
        ]);

})();

(function(){

    'use strict';

    angular
        .module('app.core')
        .config(['$routeProvider','$locationProvider',function($routeProvider,$locationProvider) {

            $routeProvider
                .when('/', {
                    templateUrl: 'src/app/login/login.html',
                    controller: 'loginController'
                })
                .otherwise({redirectTo:'/'});

                $locationProvider.html5Mode(true);
        }]);

})();

(function() {
    'use strict';

    angular
        .module('app.core')
        .factory('userservice',userservice);
  
    //********************************************************
    //LOGIN Service
    //********************************************************
    userservice.$inject = ['utility'];

    var apipath = '/rest/climbingapi';

    //[USER]________________________________________________>
    function userservice(utility) {
        var service = {
             authenticateByEmail: authenticateByEmail
        };
        return service;

        //Authenticate User by Email
        function authenticateByEmail(params){
            return utility.HttpService.sendRequest(apipath + '/user/authenticate/email/',params,'post');
        }
    }
   


})();
(function(){

    'use strict';

    var core = angular.module('app.core');

    var config = {
         appTitle: 'Motion Notion'
        ,version: '1'
    };

    core.value('config', config);

    core.config(function(){
    });

    

})();

(function(){

    'use strict';

    angular
        .module('app.core')
        .factory('common', common);

    common.$inject = ['$filter','$log','$http','$q','$location','$routeParams','utility','logger','ngMeta'];

    function common($filter,$log,$http,$q,$location,$routeParams,utility,logger,ngMeta){

        $log.log('App.Core Common has loaded.');

        var factory = {
            navigateToLink      : navigateToLink,
            routeTo             : routeTo,
            showslideshow       : showslideshow,
            odbcToDate          : odbcToDate,
            $log                : $log,
            $http               : $http,
            $q                  : $q,
            $location           : $location,
            $routeParams        : $routeParams,
            // Global Utility Functions
            utility             : utility,
            // 3rd-Party Logger
            logger              : logger,
            ngMeta              : ngMeta
        }

        return factory;

        //Link Navigation Function
        function navigateToLink(link,_blank){
            if(_blank !== undefined){
                window.open(link);
            }else{
                window.location.href = link;
            }
        };

        //Route to Path
        function routeTo(path,params){
            if(params !== undefined){
                $location.path(path).search(params);
            }else{
                $location.path(path);
            }
        };

        //Show Slideshow on Homepage Only
        function showslideshow(){
            if($location.$$url == '' || $location.$$url == '/')
                return 1;
            else
                return 0;
        };


        // convert sql odbc datetime to angular date
        function odbcToDate(odbcdate){
            return new Date(odbcdate);
        }

    };

})();

( function() {
  'use strict';

  angular.module('app.core')
  .factory("srvAuth", ['$rootScope',
    function($rootScope) {
      var srvAuth = {};


      srvAuth.fblogin = function() {
        FB.login(function (response) {
          if (response.status === 'connected') {
            // You can now do what you want with the data fb gave you.
            console.info(response,'RESPONSE');
            FB.api('/me?fields=id,email,name,picture', function(data) {
              $rootScope.$broadcast('FACEBOOK:LOGINCOMPLETE',data);
            });
          }
        },{scope: 'email',return_scopes: true});        
      }

      srvAuth.watchLoginChange = function() {
        var _self = this;
        FB.Event.subscribe('auth.authResponseChange', function(res) {
          if(res.status === 'connected'){
            FB.api('/me?fields=id,email,name,picture',function(data){
              console.log('data');
              $rootScope.$broadcast('FACEBOOK:USERLOGINACTIVE',data);
            });
          } else {
            alert('Not Connected');
          }
        });
      }

      srvAuth.logout = function() {
        var _self = this;
        FB.logout(function(response) {
          $rootScope.$apply(function() {
            $rootScope.user = _self.user = {};
          });
        });
      }

      return srvAuth;
    }

  ]);


})();
(function(){

    'use strict';

    angular
        .module('app.login',[
            
        ])
        
        .config([function(){



        }]);

})();


(function(){

    'use strict';

    angular
        .module('app.login')
        .controller('requestController', requestController);

    requestController.$inject = ['$scope','common','userservice','ngMeta'];

    function requestController($scope,common,userservice,ngMeta) {       
        //METHODS
        $scope.init = init;
       

        //Controller Init
        $scope.init();
        function init(){
            $scope.setMeta();
        }

        //Set Meta Info
        function setMeta(){
            ngMeta.setTitle('Stoker - Login');
            ngMeta.setTag('description','Please authenticate to login to your Stoker account.');
            ngMeta.setTag('url',_hostName+'/index.cfm');
        }


    };


})();
(function(){

    'use strict';

    angular
        .module('app.login')
        .controller('loginController', loginController);

    loginController.$inject = ['$scope','common','userservice','ngMeta','srvAuth'];

    function loginController($scope,common,userservice,ngMeta,srvAuth){
        //METHODS
        $scope.init = init;
        $scope.common = common;
        $scope.authenticateByEmail = authenticateByEmail;
        $scope.setMeta = setMeta;
        $scope.facebookLogin = facebookLogin;
        $scope.setSocialUser = setSocialUser;

        //VARIABLES
        $scope.user = {};
        $scope.socialUser = {
             id:""
            ,name:""
            ,image:""
            ,email:""
        };

        $scope.options = {
            'longtitle': true,
            'width': 250,
            'height': 40,
            'onsuccess': function(response) {
                var profile = response.getBasicProfile();
                var user = {}
                user.id = profile.getId();
                user.name = profile.getName();
                user.image = profile.getImageUrl();
                user.email = profile.getEmail();

                //Set Social User Object
                $scope.setSocialUser(user.id,user.name,user.imageurl,user.email);

                //Authenticate via App
                $scope.authenticateByEmail();
            }
        }

        //Facebook Login Already Active, Authenticate via Stoker
        $scope.$on("FACEBOOK:USERLOGINACTIVE",function(event,user){
            //Set Social User Object
            $scope.setSocialUser(user.id,user.name,user.imageurl,user.email);
        });

        //Facebook Login Complete, Now Authenticate via Stoker
        $scope.$on("FACEBOOK:LOGINCOMPLETE",function(event,user){
            //Set Social User Object
            $scope.setSocialUser(user.id,user.name,user.imageurl,user.email);

            //Authenticate via App
            $scope.authenticateByEmail();
        });

        //Sets the Social User Object
        function setSocialUser(id,name,imageurl,emailaddress){
            $scope.socialUser.id = id;
            $scope.socialUser.name = name;
            $scope.socialUser.image = imageurl;
            $scope.socialUser.email = emailaddress;
        }

        //Facebook Login
        function facebookLogin(){
            //Login to Facebook
            srvAuth.fblogin();
        }

        //Controller Init
        $scope.init();
        function init(){
            $scope.setMeta();
        }

        //Set Meta Info
        function setMeta(){
            ngMeta.setTitle('Stoker - Login');
            ngMeta.setTag('description','Please authenticate to login to your Stoker account.');
            ngMeta.setTag('url',_hostName+'/index.cfm');
        }

        //Authenticate User Via App
        function authenticateByEmail(){
            var gfirst = $scope.socialUser.name.split(' ').slice(0, -1).join(' ');
            var glast = $scope.socialUser.name.split(' ').slice(-1).join(' ');
            var params = {emailaddress: $scope.socialUser.email, firstname:gfirst, lastname: glast, image: $scope.socialUser.image};
            userservice.authenticateByEmail(params).then(
                function(results){
                    if(results.AUTHSTATUS[0].LOGINVALID && results.AUTHSTATUS[0].SESSIONCREATED){
                        //Authentication Success
                        common.logger.success('You have been authenticated successfully.','','Login Success');

                        //Redirect to App
                        common.navigateToLink('/index.cfm');
                    }else{

                        //Authentication Failed
                        common.logger.warn($scope.socialUser.email + ' does not have a Stoker account.','','Invalid Account');
                    }
                },function(error){
                    console.log('ERROR',error);
                    $scope.message ="Error loading events. Please reload the page.";
                }    
            ); 
        }


    };


})();


window.onbeforeunload = function(e){
    gapi.auth2.getAuthInstance().signOut();
};
(function(){

    'use strict';

    //Create Module
    var app = angular.module('app',['app.core','app.login']);
        app.controller('appController',appController);
        
    appController.$inject = ['$scope','$log','common','ngMeta'];
       

    function appController($scope,$log,common,ngMeta){
        $scope.common = common;
        $scope.init = init;

        $scope.init();
        function init(){
            common.ngMeta.init();
        }
    }

    //Google Sign In Button Directive
    app.directive("googleSignInButton",function(){
        return {
            scope: {
                 buttonId: '@'
                ,options: '&'
            },
            template: '<div></div>',
            link: function(scope, element, attrs) {
                var div = element.find('div')[0];
                div.id = attrs.buttonId;
                gapi.signin2.render(div.id, scope.options()); //render a google button, first argument is an id, second options
            }
        }    
    });

    //Facebook Login SDK Initialize
    app.run(['$rootScope','$window','srvAuth',function($rootScope, $window, srvAuth){
        $rootScope.user = {};

        $window.fbAsyncInit = function() {
            // Executed when the SDK is loaded
            FB.init({ 
                 appId: '2275279745903452'
                ,channelUrl: 'fbauth/channel.html'
                ,status: true
                ,cookie: true
                ,xfbml: true 
            });

            srvAuth.watchLoginChange();
        };

        (function(d){            
            //load the Facebook javascript SDK
            var js, 
            id = 'facebook-jssdk', 
            ref = d.getElementsByTagName('script')[0];

            if (d.getElementById(id)) {
              return;
            }

            js = d.createElement('script'); 
            js.id = id; 
            js.async = true;
            js.src = "//connect.facebook.net/en_US/all.js";

            ref.parentNode.insertBefore(js, ref);
        }(document));
    }]);
  

    //Header Custom Directive
    /*app.directive("customheader",function(){
        return{
             restrict: 'E'
            ,templateUrl: '/src/app/partials/header.html'
        };
    });

    //Footer Custom Directive
    app.directive("customfooter",function(){
        return{
             restrict: 'E'
            ,templateUrl: '/src/app/partials/footer.html'
        };
    });*/

    
})();